<template lang="pug">
  div
    el-alert(
      title="Bu bölümü görüntüleme yetkiniz yok"
      v-if="!can('branches.list')"
      :closable="false"
      type="error"
      show-icon
      center
    )
    el-card(body-style="padding:0" v-if="can('branches.list')")
      div(slot="header")
        el-form(inline)
          el-form-item
            el-button(
              type="primary"
              @click="create"
              icon="el-icon-plus"
              :disabled="!can('branches.create')"
              size="mini"
            ) Oluştur
          el-form-item
            el-input.pull-right(
              v-model="search"
              suffix-icon="el-icon-search"
              placeholder="Ara"
              clearable
              size="mini"
            )
      el-table(:data="filtered" @row-dblclick="edit" stripe)
        el-table-column(prop="name" label="Şube")
        el-table-column(prop="address" label="Adres")
        el-table-column(prop="id" width="120px")
          template(v-slot="props")
            el-button-group
              el-button.micro(icon="el-icon-edit" @click="edit(pros.row)" size="mini")
              el-button.micro(icon="el-icon-delete" @click="delete(pros.row)" size="mini" type="danger")
    el-dialog(
      :visible.sync="modal"
      :title="company.hasOwnProperty('id') ? 'Düzenle' : 'Oluştur'"
      center
      width="400px"
    )
      el-form(v-model="company")
        el-form-item(label="Şube adı")
          el-input(v-model="company.name")
        el-form-item(label="Adres")
          el-input(v-model="company.address")
      el-button(@click="save" type="primary") Kaydet
</template>
<script>
import Company from '@/models/Company'
export default {
  name: 'Branches',
  data() {
    return {
      fetching: false,
      saving: false,
      modal: false,
      company: new Company({ name: '', type: 'branch' }),
      companies: [],
      search: ''
    }
  },
  async created() {
    if (this.can('branches.list')) {
      await this.fetch()
    }
  },
  methods: {
    async fetch() {
      try{
        this.fetching = true
        this.companies = await Company.where('type', 'branch').get()
        this.fetching = false
      } catch({ response }) {
        this.$message.error(response.data.message)
        this.fetching = false
      }
    },
    create() {
      this.company = new Company({ type: 'branch', name: '' })
      this.modal = true
    },
    edit(row) {
      this.company = row
      this.modal = true
    },
    async save() {
      try{
        this.saving = true
        await this.company.save()
        this.saving = false
        this.modal = false
        await this.fetch()
      } catch({ response }) {
        this.$message.error(response.data.message)
        this.saving = false
      }
    },
    delete() {

    }
  },
  computed: {
    filtered: function(){
      return this.companies.filter(a => a.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  }
}
</script>
